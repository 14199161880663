.serviceContainer {
    color: #0E334F;
    background-color: #F2F8FE;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 4%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }



    h2 {
        font-size: 32px;
        font-weight: 600;
        width: 90%;

        @media (max-width: 600px) {
            text-align: left;
        }

        @media (max-width: 420px) {
            font-size: 26px;
        }
    }
}

.serviceInfoContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    padding-top: 5%;
    text-align: left;

    @media (max-width: 700px) {
        flex-direction: column-reverse;
    }
}

.serviceText {
    width: 60%;

    p {
        width: 80%;
        margin-bottom: 6%;

        @media (max-width: 1000px) {
            width: 95%;
        }
    }

    h3 {
        font-weight: 600;
        margin-bottom: 3%;

        @media (max-width: 600px) {
            margin-bottom: 4%;
        }
    }

    @media (max-width: 1000px) {
        width: 50%;
        margin-right: 10px;
    }

    @media (max-width: 700px) {
        width: 100%;
    }
}

.techImage {
    width: 100%;
    object-fit:cover;
    

    @media (max-width: 1000px) {
        height: 100%;
    }

    @media (max-width: 850px) {
        width: 100%;
    }
}

.serviceImage {
    display: flex;
    justify-content: flex-end;
    width: 40%;

    @media (max-width: 1000px) {
        width: 400px;
        justify-content: center;
    }

    @media (max-width: 700px) {
        width: 100%;
        margin-bottom: 4%;
    }
}