.aboutEjContainer {
    background-color: #006EE9;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 6%;
    padding-bottom: 2%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 600px) {
        padding-bottom: 4%;
        margin-bottom: 4%;
    }
}

.makeLine {
    border: 5px solid white;
    width: 100vw;
    margin-top: 1%;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    padding-bottom: 3%;
    //border-bottom: 10px solid white;


    @media (max-width: 750px) {
        flex-direction: column-reverse;
    }
}

.aboutEjLeft {
    justify-content: center;
    align-items: center;
    width: 60%;
    text-align: left;

    h3 {
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 2%;

        @media (max-width: 600px) {
            margin-bottom: 4%;
        }
    }

    p {
        margin-bottom: 2%;
        width: 90%;

        @media (max-width: 750px) {
            margin-bottom: 4%;
            width: 100%;
        }
    }

    @media (max-width: 750px) {
        width: 100%;
    }

    @media (max-width: 400px) {
        margin-top: 5%;
    }
}

.aboutEjRight {
    width: 40%;
    display: flex;
    justify-content: center;

    @media (max-width: 980px) {
        align-items: center;
        margin-bottom: 10%;
    }

    @media (max-width: 750px) {
        width: 90%;
        padding-bottom: 3%;
    }
}

.ejFounder {
    width: 500px;
    height: 400px;
    z-index: 1;
    position: absolute;
    object-fit: cover;

    @media (max-width: 1350px) {
        width: 450px;
        height: 350px;
    }

    @media (max-width: 1150px) {
        width: 400px;
        height: 300px;
    }

    @media (max-width: 950px) {
        width: 325px;
        height: 225px;
    }

     @media (max-width: 400px) {
         width: 250px;
         height: 150px;
     }

}

.borderDiv {
    position: relative;
    border: 5px solid white;
    background-color: transparent;
    z-index: 0;
    width: 500px;
    height: 400px;
    bottom: -45px;
    right: -45px;

    @media (max-width: 1350px) {
        width: 450px;
        height: 350px;
    }

    @media (max-width: 1150px) {
        width: 400px;
        height: 300px;
    }

    @media (max-width: 950px) {
        width: 325px;
        height: 225px;
        bottom: -35px;
        right: -35px;
    }

    @media (max-width: 400px) {
        bottom: -25px;
        right: -25px;
        width: 250px;
        height: 150px;
    }

}

.ejButton {
    text-align: center;

    @media (max-width: 600px) {
        margin-bottom: 4%;
    }

}

.buttonStyle {
    color: white;
    background-color: #044372;
    border-radius: 0;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10%;
    font-weight: 600;
}