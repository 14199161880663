@import 'colours';

.contacted {
    background: inherit;
    color: inherit;

}

//Contact us
.contact-form {
    background: $light-background;
    border-radius: 0px;
    max-width: 1000px;
    font-family: 'Poppins', sans-serif;

    @media (min-width: 415px) {
        padding: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border: 1px solid #ccc;
    }

    h3 {
        color: black;
        padding-bottom: 25px;
        text-transform: none;
    }

    form {
        color: black;

        label {
            margin-top: 20px;
            font-family: 'Poppins', sans-serif;
        }

        input,
        textarea {
            margin-bottom: 0px;
            width: 100%;
            padding: 10px;
            font-family: 'Poppins', sans-serif;
        }

        .button {
            display: flex;
            justify-content: center;
        }

        button {
            margin-top: 10px;
            background-color: $ej-primary;
            color: white;
            padding: 15px 20px;
            border: none;
            cursor: pointer;
        }

        .contact-error {
            color: darkred;
        }
    }

    p {
        color: black;
        padding-top: 10px;
    }
}

.formTerms {
    color: dimgray !important;
    font-weight: 300 !important;
}