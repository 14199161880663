.subscribeContent {
    //padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%

}

.ejButton {
    background-color: #006EE9 !important;
    color: white !important;
    border: none !important;
    cursor: pointer !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    height: 40px !important;
    line-height: 0px !important;
    font-weight: 600 !important;
}

.formSize {
    width: 100% !important
}

.clear {
    text-align: center;
}

#mc_embed_signup {
    width: 100% !important
}

.subscribeText {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 7%;
    font-family: "Poppins", sans-serif !important;
    padding-left: 10%;
    padding-right: 10%;
}

#mc-embedded-subscribe-form {
    padding-left: 20px;
}

.subscribeList {
    list-style-position: inside;
    text-align: left;
}

.subscribeBold {
    font-weight: 600;
}

.alignLeft {
    text-align: left;
}