@import 'colours';

body {
    font-family: 'Poppins', sans-serif !important;

    //@media (min-width: 415px) {
    //    background-image: url('../images/background.svg');
    //    background-size: cover;
    //    background-position: center;
    //    background-repeat: no-repeat;
    //    background-attachment: fixed;
    //}
}

h1 {
    font-family: 'Poppins', sans-serif;
    padding-top: 20px;
    text-transform: uppercase;
}
//**NavBar
//Container
.navbar {
    background-color: white;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: center;

    @media (min-width: 415px) {
        padding: 0px;
        background-color: white;
        padding-left: 5%;
        padding-right: 5%;
    }

    width: 100%;
    flex-direction: column;
}
//Top section of nav
.containerTest {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //border-bottom: 2px solid #70707080
}
//**Contact info, #, @
//Contact info container
.contactInfo {
    width: 80%;
    margin-top: 20px;
    display: flex;
    min-width: 70%;


    @media (max-width: 700px) {
        flex-direction: column;
        margin-top: 5px;
    }
}

.mobileImageDiv {
    display: none;
    //border: 1px solid red;
    
    

    @media (max-width: 800px) {
        display: flex;
        height: 100%;
    }
}

.mobileImage {
    width: 300px;
    //border: 1px solid green;

    @media (max-width: 400px) {
        width: 200px;
    }

}
//Contain icon and text together
.infoContainer {
    display: flex;
    flex-direction: row;
    width: 17%;

    a {
        text-decoration: none;
        color: inherit;
    }

    @media (max-width: 800px) {
        display: none;
    }

    @media (min-width: 1501px) {
        width: 25%;
    }

    @media (max-width: 1500px) {
        width: 26%;
    }

    @media (max-width: 1400px) {
        width: 30%;
    }

    @media (max-width: 1200px) {
        width: 37%;
    }

    @media (max-width: 950px) {
        width: 45%;
    }

    @media (max-width: 850px) {
        width: 55%;
    }

    @media (max-width: 700px) {
        width: 100%;
        height: 45px;
    }
}
//Icon for Phone and Email
.navIcon {
    width: 6%;
    float: left;
    margin-right: 5px;

    @media (max-width: 700px) {
    }
}

.contactInfo p {
    margin-top: 15px;
    color: #0E334F;
    font-weight: 600;
    font-size: 17px;

    @media (max-width: 700px) {
        font-size: 15px;
    }
}
// Top section of nav, right
.rightSide {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 25vw;
}
//Socials icons container
.socContainer {
    display: flex;
    height: 60%;
    align-items: flex-end;
    justify-content: space-evenly;
    margin-top: auto;
    gap: 5px;

    a {
        text-decoration: none;
        height: 70%;
    }

    @media (max-width: 575px) {
        display: none;
    }
}

//Icon size
.socIcon {
    height: 70%;
    margin-left: 5px;

    @media (max-width: 600px) {
        height: 60%;
    }


}
//**Burger Menu
.burgerHolder {
    //background-color: white;
    //border: 1px solid red;
    display: none;
    position: absolute;
    top: 10px;
    right: 0;

    @media (max-width: 575px) {
        display: flex;
        text-align: right;
    }
}
//Drop down background
.navItems {
    background-color: white;
    width: 95vw;
    text-align: center;


    .nav-link {
        //border: 1px solid red;
        //padding-right: 0;
        width: 100%;
    }

    a {
        display: flex;
        justify-content: center;
    }

    

}
//Target text in dropdown
.nav-link {
    padding-left: 10px;
    padding-right: 10px;
    color: #0E334F;
    font-weight: 600;
}

.burgerNavElement {
    font-size: 16px !important;
}
//Bottom section of nav
.containerTest2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2%;

    @media (max-width: 575px) {
        display: none;
    }
}
//ul container
.listDiv {
    width: 90%;
    border-top: 2px solid #70707080;
    //border: 2px solid green;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-top: 1%;

    @media (max-width: 1050px) {
        width: 100%;
        //justify-content: center;
    }
}
//Menu highlight
.contactUs {
    background-color: #006EE9;
    color: white !important;

}

.contactUs:hover {
    border-bottom: 2px solid transparent !important;
}

.bootstrapUL {
    //margin-top: 3%;
    //border: 1px solid red;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    //position: relative;


    @media (min-width: 1350px) {
        width: 70%
    }

    @media (max-width: 980px){
        width: 100%;
    }

    @media (max-width: 900px) {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }

    .nav-link {
        font-size: 15px;
        //white-space: nowrap;
        //background-color: red;
        //margin-right: 5%;
        padding: 10px 25px 10px 25px !important;
        //max-height: 60px;
        //width: 20%;
        text-align: center;
        //flex-basis: 15%;

        @media (max-width: 901px) {
            padding: 10px 17px 10px 17px !important;
        }

        @media (max-width: 800px) {
            padding: 10px 19px 10px 19px !important;
        }

        @media (max-width: 615px) {
            padding: 10px 15px 10px 15px !important;
        }
    }
}

.mainNavLink {
    border-bottom: 2px solid transparent;
    transition: border-color 0.5s;

    div {
        border: none;
        border-radius: 0;
        box-shadow: -8px 8px 4px rgba(0, 0, 0, 0.1);

        a {
            color: #0E334F;
            font-weight: 600;
        }
    }
}

.mainNavLink:hover {
    border-bottom: 2px solid #0E334F;
}

.activeLink {
    color: #006EE9 !important;

    a {
        color: #006EE9;
    }
}



.burgerBorderHider {
    div {
        box-shadow: none;
    }
}
.burgerBorderHider:hover {
    border-bottom: 2px solid transparent !important;
}

.navbar ul {
    list-style-type: none;
    width: 100%;
    //border-top: 2px solid #70707080;
    //overflow: auto;
    
    @media (max-width: 800px) {
        display: flex;
        overflow:hidden;
        flex-direction: row-reverse;
        justify-content: space-between;
        text-align: center;
    }
}

.navbar li {
    float: right;
    //margin-top: 3%;
    margin-left: 5%;
    //padding: 10px;
    padding-left: 14px;
    padding-right: 14px;
    color: #0E334F;
    font-weight: 600;
    //background-color: green;

    @media (max-width: 1200px) {
        margin-left: 5px;
        padding-left: 12px;
        padding-right: 12px;
    }

    @media (max-width: 950px) {
        margin-left: 0;
        padding-left: 8px;
        padding-right: 8px;
    }

    @media (max-width: 780px) {
        padding-left: 10px;
        padding-right: 10px;
    }

    @media (max-width: 700px) {
        padding-left: 5px;
        padding-right: 5px;
    }
}
//EJ logo container
.imageDiv {
    border-top: 2px solid #70707080;
    width: 30%;
    display: flex;
    align-items: center;

    @media (max-width: 800px) {
        display: none;
    }
}
//EJ logo
.navbar-image {
    width: 300px;

    @media (min-width: 415px) {
        width: 100%
    }

    @media (min-width: 1280px) {
        width: 70%;
    }
}

.home-row {
    display: flex;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;


    @media (min-width: 1280px) {
        padding-left: 15%;
        padding-right: 15%;
    }
}

.emphasis-text {
    color: $ej-light-blue;
}

.seeMe {
    width: 100px;
    text-align: center;
}

.burgerSubMenu {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    //background-color: red;
    //margin-top: 10px;
    //margin-bottom: 3px;


    
    


    button {
        background-color: white !important;
        border: none;
        color: #0E334F !important;
        font-weight: 600;
    }

    div {
        //background-color: red;
        //position: absolute !important;
        text-align: center;
        border: none;
        border-radius: 0;
        padding-bottom: 0;
        left: 10px;


        @media (max-width: 385px) {
            font-size: 14px;
        }
    }

    a {
        //height: 36.5px;
        //background-color: red;
        color: #0E334F;
        font-weight: 600;
    }



    @media (max-width: 385px) {
        a {
            padding-right: 5px;
        }
    }
}

.dropdown-toggle::after {
    display: none;
}

.caseStudyButton {
    //border: 1px solid red;
    width: 50px;
    padding-right: 150px;

    @media (max-width: 925px) {
        padding-right: 135px;
    }

    @media (max-width: 900px) {
        padding-right: 115px;
    }

    @media (max-width: 650px) {
        padding-right: 125px;
    }
}