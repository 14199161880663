.footerForm {
    background-color: none;

    button {
        width: 10%;
        padding: 5px;
        border: none;
        color: white;
        background-color: #006EE9;
        font-weight: 600;
        margin-top: 2%;

        @media (max-width: 1300px) {
            width: 15%;
        }

        @media (max-width: 1100px) {
            width: 20%;
            margin-top: 4%;
        }

        @media (max-width: 700px) {
            width: 40%;
        }
    }

    input {
        width: 20%;
        border: none;
        padding: 5px;

        @media (max-width: 1100px) {
            width: 30%;
        }

        @media (max-width: 700px) {
            width: 50%;
        }
    }
}

    .footerFormOuter {
        display: flex;
        flex-direction: column;

        h5 {
            margin-top: 1%;
            color: white;
            font-size: 16px;
        }
    }


    .footerError {
        color: white;
    }