.companyDescContainer {
    background-color: #F2F8FE;
    color: #0E334F;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4%;
    padding-top: 4%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    h2 {
        font-weight: 600;
        font-size: 32px;
        width: 90%;
        margin-bottom: 4%;

        @media (max-width: 600px) {
            text-align: left;
        }
    }

    p {
        font-size: 17px;
    }
}

.imageBox {
    width: 90%;
    text-align: left;
    margin-top: 1%;

    h3 {
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 2%;
    }
}

.freshCheckLogoContainer {
    float: right;
    padding-left: 80px;
    padding-bottom: 40px;

    @media(max-width: 1100px) {
        padding-left: 50px;
    }

    @media(max-width: 1000px) {
        padding-left: 10px;
    }

    @media (max-width: 950px) {
        float: none;
        display: flex;
        justify-content: center;
        margin-bottom: 4%;
    }
}

.fcLogo {
    width: 100%;
    @media (max-width: 950px) {
        width: 80%;
    }
}
