.teamSeg {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 4%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 600px) {
        padding-top: 4%;
    }
}

.teamSegOne {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 900px) {
        width: 100%;
    }

    h2 {
        margin-top: 2%;
        margin-bottom: 2%;
        color: #0E334F;
        font-weight: 600;

        @media (max-width: 600px) {
            margin-bottom: 4%;
        }

        @media (max-width: 900px) {
            width: 95%;
            text-align: left;
        }
    }

    p {
        width: 60%;
        font-family: 'Poppins', sans-serif;
        color: #0E334F;
        margin-bottom: 30px;
        text-align: center;

        @media (max-width: 900px) {
            width: 95%;
            text-align: left;
        }
    }
}
//Image of person
.headshot {
    height: 230px;
    width: 200px;
    object-fit: cover;
    z-index: 0;

    @media (max-width: 1080px) {
        height: 90%;
        width: 100%;
    }
}
//Name+Title container
.personInfo {
    background-color: #006EE9;
    position: relative;
    bottom: 15px;
    left: 10px;
    width: 90%;
    text-align: center;

    @media (max-width: 1080px) {
        width: 100%;
        left: 0;
    }
}
//Name text
.personName{
    color: white;
    margin:0;
    padding: 5px;
    font-weight: 800;
}
//Job title text
.personTitle {
    padding-bottom: 5px;
    color: white;
    font-weight: 400;
}
//Director panel
.teamSegTwo {
    display: flex;
    flex-direction: row;
    gap: 45px;
    margin-bottom: 45px;
    width: 100%;
    justify-content: center;

    @media (max-width: 800px) {
        //gap: 0px;
        //flex-direction: column;
        //width: 80%;
        //margin-bottom: 0;
    }

    @media (max-width: 450px) {
        flex-direction: column;
        align-items: center;
        gap: 45px;
    }
}
//Employee panel
.teamSegThree {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width: 1080px) {
        gap: 45px;
        flex-direction: row;
        width: 100%;

        p {
            font-size: 14px;
        }
    }

    @media (max-width: 450px) {
        flex-direction: column;
        align-items: center;
        gap: 45px;
    }
}

.cardContainer {
    @media (max-width: 1080px) {
        width: 40%
    }

    @media (max-width: 450px) {
        width: 80%
    }
}