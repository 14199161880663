@import 'colours';
//Section container
.offeringContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #0E334F;
    padding-top: 4%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    h2 {
        font-weight: 600;
        margin-bottom: 2%;
        width: 70%;

        @media (max-width: 900px) {
            margin-bottom: 4%;
            text-align: left;
            width: 95%;
        }
    }

    .offeringDesc {
        width: 70%;
        margin-bottom: 2%;


        @media (max-width: 900px) {
            text-align: left;
            width: 95%;
            margin-bottom: 5%;
        }
    }

    margin-bottom: 7%;
}
//Card image
.whatWeImage {
    width: 65%;
    padding: 10%;

    @media (max-width: 768px) {
        padding-bottom: 10%;
    }
}
//Card container
.iconContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 95%;
    align-items: center;

    gap: 2%;
}
//Card content
.iconCard {
    flex-basis: 31.3%;
    background-color: #F2F8FE;
    margin-bottom: 20px;

    @media (max-width: 900px) {
        flex-basis: 42%;
        min-width: 49%;
    }

    @media (max-width: 600px) {
        flex-basis: 100%;
        
        min-width: 60%;
        padding: 10px;
    }

    p {
        color: #006EE9;
        font-weight: 600;
        font-size: 26px;

        @media (max-width: 400px) {
            font-size: 18px;
        }
        padding-bottom: 30px;
    }

    
        text-decoration: none;
    
}
//Inverse card
.iconCardBackground {
    background-color: #006EE9;
    p {
        color: white;
    }
}