@import 'colours';

//Section container
.caseSeg {
    //padding-top: 2%;
    padding-bottom: 100px;
    border-bottom: 2px solid #70707080;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    a {
        text-decoration: none;
    }
}
//Holds section title+description
.textSeg {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        color: #0E334F;
        font-weight: 600;

        @media (max-width: 900px) {
            text-align: left;
            width: 95%;
        }

        @media (max-width: 600px) {
            margin-bottom: 4%;
        }
    }

    p {
        color: #0E334F;
        font-family: 'Poppins', sans-serif;
        width: 65%;

        @media (max-width: 900px) {
            width: 95%;
            text-align: left;
        }
    }
}
//Text+Image container
.imageSeg {
    display: flex;
    flex-direction: row;
    width: 80%;

    @media (max-width: 764px) {
        flex-direction: column;
    }
}
//Case card, holds text+image
.caseCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    flex-basis: 45%;

    p {
        font-weight: 600;
        font-size: 20px;
        width: 100%;
        text-align: center;
    }

    @media (max-width: 764px) {
        margin: 5px;
    }
}
//Image on case card
.caseImage {
    width: 100%;
    height: 500px;
    object-fit: cover;
    margin-bottom: 2%;

    @media(max-width: 1600px) {
        height: 450px;
    }

    @media(max-width: 1500px) {
        height: 400px;
    }

    @media(max-width: 1400px) {
        height: 350px;
    }

    @media(max-width: 1300px) {
        height: 325px;
    }

    @media(max-width: 1100px) {
        height: 300px;
    }

    @media(max-width: 1000px) {
        height: 275px;
    }

    @media(max-width: 900px) {
        height: 250px;
    }

    @media(max-width: 765px) {
        height: auto;
    }
}
