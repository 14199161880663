@import 'colours';

.footerContainer {
    display: flex;
    background-color: #006EE9;
    padding-bottom: 1%;
    padding-top: 3%;
    color: white;
    //flex-direction: row;

    p {
        color: white;
        line-height: 14px;
    }

    @media (max-width: 800px) {
        flex-direction: column;
    }
}

.textBoxLeft {
    width: 45%;
    text-align: left;
    margin-left: 5%;

    @media (max-width: 400px) {
        font-size: 14px;
    }

    @media (max-width: 800px) {
        width: 90%;
    }
}

.textBoxRight {
    width: 45%;
    text-align: right;
    margin-right: 5%;

    a {
        text-decoration: none;
        color: inherit;
    }


    @media (max-width: 400px) {
        font-size: 14px;
    }

    @media (max-width: 800px) {
        text-align: left;
        margin-right: 0;
        margin-left: 5%;
        width: 90%;
    }
}

//Get in Touch section
.getInTouch {
    margin-top: 0;
    background-color: #044372;
    display: flex;
    flex-direction: column;
    padding-bottom: 3%;

    @media (max-width: 800px) {
        padding-top: 3%;
    }

    p {
        margin-top: 1%;
        background-color: #044372;
        font-weight: 400;
        position: relative;
        z-index: 2;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 10px;
        margin-right: 10px;
        color: white;
        text-align: center;
    }
}
//**Container for line + text
.centerThis {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
//Linediv
.lineDiv {
    border: 2px solid #006EE9;
    height: 1%;
    position: relative;
    width: 14%;
    z-index: 1;
    bottom: 30px;

    @media (max-width: 1300px) {
        width: 17%;
    }

    @media (max-width: 1000px) {
        width: 22%;
    }

    @media (max-width: 750px) {
        width: 27%;
    }

    @media (max-width: 600px) {
        width: 35%;
    }

    @media (max-width: 500px) {
        width: 60%;
    }
}

.inputHolder {
    text-align: center;
    margin-top: 1%;

    input {
        width: 20%;
        border: none;
        padding: 5px;

        @media (max-width: 1100px) {
            width: 30%;
        }

        @media (max-width: 700px) {
            width: 50%;
        }
    }

    ::placeholder {
        color: #044372;
        padding-left: 5px;
    }
}

.buttonHolder {
    text-align: center;
    margin-top: 1%;

    button {
        width: 10%;
        padding: 5px;
        border: none;
        color: white;
        background-color: #006EE9;
        font-weight: 600;

        @media (max-width: 1300px) {
            width: 15%;
        }

        @media (max-width: 1100px) {
            width: 20%;
            margin-top: 4%;
        }

        @media (max-width: 700px) {
            width: 40%;
        }
    }
}

.footerContainerMain {
    padding: 0;
    margin: 0;
}


.socContainerFoot {
    //border: 1px solid red;
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 800px) {
        display: none;
    }
}
.socIconFoot {
    height: 50px;
    margin-left: 5px;
    margin-right: 5px;

    @media (max-width: 800px) {
        height: 40px;
    }

    @media (max-width: 500px) {
        height: 30px;
    }
}

.socContainerFootLazy {
    display: none;
    //border: 1px solid red;
    margin-left: 5%;

    @media(max-width: 800px) {
        display: flex;
    }
}

.pushMeSmall {
    margin-left: 5%;
    margin-top: 2%;

    @media(min-width: 800px) {
        display: none;
    }
}