@import 'colours';

.dark-row {
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: white;
    padding-left: 10%;
    padding-right: 10%;

    @media (min-width: 1280px) {
        background-color: white;
    }
}

.row-center {
    vertical-align: central;
    text-align: center;
    background-color: white;
}

.left-align {
    text-align: left !important;
}

//Offering
.offering-row {
    padding-top: 40px;
    padding-bottom: 40px;
}

//Founders
.founders-row {
    @media (min-width: 415px) {
        background-image: url('../images/expert-background.png');
        background-repeat: round;
        padding-left: 15%;
        padding-right: 15%;
    }
}
//Expertise
.expertise-row {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $ej-primary;

    @media (min-width: 415px) {
        padding-left: 15%;
        padding-right: 15%;
    }
}
//Case study
.case-study-row {
    padding-top: 40px;
}
//How we can help
.how-we-can-help-row {
    padding-top: 40px;
    padding-bottom: 20px;
    background-color: $ej-dark-blue;
    color: white;
}
//Testimonials
.testimonial-row {
    background-color: transparent;
    padding-top: 40px;
    padding-bottom: 40px;
}
//Give us a chance
.give-us-a-chance-row {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: transparent;
}
//Benifits
.benifits-row {
    padding-top: 40px;
    padding-bottom: 20px;
    background-color: $ej-grey-blue;
    color: black;
}
//Privacy
.privacy-row {
    padding-left: 15%;
    padding-right: 15%;
    background-color: $light-background;
}

//How we can help
.contact-row {
    padding-top: 40px;
    padding-bottom: 20px;
    background-color: white;
    color: white;
}

//Footer
.footer-row {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $ej-primary;
    color: white;
}


div.title {
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;

    h1 {
        font-size: 32px;
        text-transform: uppercase;
    }

    h1.light {
        color: white;
        border-bottom: 3px solid $ej-light-blue;
    }

    h1.dark {
        color: black;
        border-bottom: 3px solid $ej-primary;
    }
}

