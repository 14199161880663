@import 'colours';

.contact {
    background: white;
    color: black;
}
.contacted {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
//Section container
.segOne {
    display: flex;
    flex-direction: row;
    font-family: 'Poppins', sans-serif;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 4%;
    color: #0E334F;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
    }
}
//Contact us
.contact-form {
    background: $light-background;
    border-radius: 0px;
    height: 95%;
    width: 80%;
    margin: auto;

    @media (min-width: 415px) {
        padding: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 1200px) {
        width: 100%;
    }

    h3 {
        color: #0E334F !important;
        font-weight: 600;
        font-size: 22px;
    }

    .readForm {
        font-weight: 400;
        font-size: 19px;
    }

    .submitButton {
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    form {
        width: 95%;
        font-weight: 600;
        color: #0E334F !important;

        @media (max-width: 900px) {
            width: 100%;
        }

        input,
        textarea {
            width: 100%;
            padding: 5px;
            border: 1px solid #044372;
        }

        .button {
            display: flex;
            justify-content: center;
        }

        button {
            background-color: #006EE9 !important;
            color: white;
            border: none;
            cursor: pointer;
            padding-left: 50px;
            padding-right: 50px;
            height: 40px;
            line-height: 0px;
            font-weight: 600;
        }

        p {
            color: gray;
            font-size: 16px;
            font-weight: 100;
            width: 100%;
        }
    }
}
//Form holding div
.segOneRight {
    width: 50%;
    display: flex;

    @media (max-width: 900px) {
        width: 90vw;
        margin-top: 2%;
    }
}
//Awkward button attempt
.centerMe {
    display: flex;
    justify-content: center;
}

.segOne h2 {
    color: #0E334F;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 10%;
}

.segOne p {
    width: 70%;
    color: #0E334F;
    margin-bottom: 10%;
}

.newButton {
    border: 2px solid #006EE9;
    color: #006EE9;
    background-color: white;
    border-radius: 0;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    padding-left: 40px;
    padding-right: 40px;
}

.segOneLeft {
    width: 50%;
    margin-top: 5%;
    margin-left: 3%;

    @media (max-width: 900px) {
        width: 90vw;
        text-align: center;
        padding: 0;
        margin: 0;

        p {
            width: 100%;
            font-family: 'Poppins', sans-serif;
        }
    }
}

.introButton {
    margin-right: 38%;

    @media (max-width: 900px) {
        margin: 0;
    }
}

.altTitle {
    width: 90%;

    @media (max-width: 900px) {
        width: 100%;
    }

    @media (max-width: 850px) {
        margin-top: 10px
    }
}

.less-margin {
    margin-bottom: 4% !important
}

.bold-text {
    font-weight: bold
}

.pointer-cursor {
    cursor: pointer;
}