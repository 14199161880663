.blog-container-page {
    text-align: left;
    padding-left: 10%;
    padding-right: 10%
}

.blog-content-image {
    img {
        max-height: 500px;
        width: 100%;
        object-fit: cover;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}