.outcomeContainer {
    background-color: #006EE9;
    color: white;
    display: flex;
    justify-content: center;
    padding-bottom: 4%;
    padding-top: 4%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.textContainer {
    width: 90%;
    text-align: left;

    h3 {
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 1%;
        margin-top: 2%;

        @media (max-width: 600px) {
            margin-bottom: 4%;
        }
    }

    p {
        margin-bottom: 15px;
    }
}