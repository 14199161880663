.helpContainer {
    color: white;
    background-color: #006EE9;
    text-align: left;
    display: flex;
    justify-content: center;
    padding-top: 4%;
    padding-bottom: 4%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.helpTextContainer {
    width: 90%;

    h2 {
        font-weight: 600;
        margin-bottom: 2%;
        font-size: 26px;

        @media (max-width: 600px) {
            margin-bottom: 6%;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 18px;

        @media (max-width: 600px) {
            margin-bottom: 4%;
        }
    }

}