//Whole container
.contactCompContainer {
    display: flex;
    background-color: #F2F8FE;
    color: #0E334F;
    justify-content: center;
    padding-top: 4%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 600px) {
        padding-bottom: 6%;
    }
}
//Inner div
.contactContentContainer {
    width: 40%;
    text-align: left;
    padding-bottom: 4%;

    @media (max-width: 1300px) {
        width: 50%;
    }

    @media (max-width: 650px) {
        width: 100%;
    }

    h3 {
        font-weight: 600;
        margin-top: 4%;
        margin-bottom: 4%;

        @media (max-width: 1300px) {
            margin-top: 6%;
        }
    }
}
.containerInner {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 650px) {
        flex-direction: column;
        align-items: center;
    }
}
//Text container
.contactCompText {
    
}
//Image container
.contactImageContainer {
    margin-top: 8%;

    @media (max-width: 650px) {
        margin-top: 4%;
        margin-bottom: 4%;
    }
}
//Item div
.contactInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 70px;
    line-height: 70px;

    a {
        text-decoration: none;
        color: inherit;
    }


    @media (max-width: 650px) {
        height: 40px;
        line-height: 40px;
    }

    p {
        font-weight: 600;
        font-size: 18px;
    }
}
//Images
.contactIcon {
    width: 6%;
    margin-right: 8%;

    @media (max-width: 650px) {
        margin-right: 5%;
    }
}

//Form container
.contactCompForm {
    display: flex;
    width: 40%;
    text-align: left;

    @media (max-width: 1300px) {
        width: 50%;
    }

    @media (max-width: 650px) {
        width: 100vw;
        padding-bottom: 4%;
    }
}