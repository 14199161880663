//Section container
.blog-container {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 1000px) {
        flex-direction: column;
    }
}

.show-all-button {
    margin-bottom: 8px;
}

.history-list {
    h3 {
        cursor: pointer;
    }

    p {
        cursor: pointer;
    }
}

.history-container {
    width: 40%;
    text-align: left;
    margin: auto;
}

.highlight-selected {
    text-decoration: underline;
}


.blog-list-container {
    display: flex;
    flex-direction: column;
}

.pointer {
    cursor: pointer;
}

.blog-list-time {
    border-bottom: 2px solid #006EE9;
    margin-bottom: 15px;

    div {
        display: flex;
    }
}

@media(max-width: 575px) {
    .history-container {
        margin: initial;
        padding-left: 10px;
    }

    .blog-list-time {
        padding-left: 10px;
    }
}

.blog-list-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.blog-list-entry {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.blog-list-image {
    height: 150px;
}

.blog-list-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.blog-list-entry:hover {
    background-color: #006EE9;
    color: #fff;
    cursor: pointer;
    transition: background-color linear 0.5s;
    transition: color linear 0.1s;
}

.blog-list-entry-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;

    p {
        margin-bottom: 0.5rem
    }
}

.blog-list-entry-desc {
    display: flex;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;

    p {
        margin: 0
    }
}

.add-pointer {
    padding-left: 10%;
    display: flex;
    justify-content: flex-start;

    i {
        cursor: pointer;
    }
}

.blog-content {
    padding-left: 10%;
    padding-right: 10%;
}

.blog-content-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 15px;
}

.blog-content-content {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
        max-height: 300px;
    }
}

.recommended-blog-container {
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
}

.recommended-blogs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
}

.recommended-blog-item {
    width: 20%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    
    img {
        max-height: 250px
    }
}

@media(max-width: 1300px) {
    .recommended-blog-item {
        width: 30%
    }
}

@media(max-width: 850px) {
    .recommended-blog-item {
        width: 45%
    }
}

@media(max-width: 400px) {
    .recommended-blog-item {
        width: 80%
    }

    .recommended-blogs {
        justify-content: center;
        gap: 10px;
    }

    .blog-list-entry-title > p {
        display: none;
    }

    .blog-list-entry-desc > p {
        display: none;
    }

    .blog-list-entry {
        flex-direction: column;
    }

    .blog-list-image > img {
        width: 90%;
        object-fit: cover;
    }
}