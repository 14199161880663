.benefitsContainer {
    display: flex;
    flex-direction: column;
    padding-top: 4%;
    padding-bottom: 4%;
    align-items: center;
    color: #0E334F;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    h2 {
        font-weight: 600;
        margin-bottom: 4%;
        font-size: 26px;

        @media (max-width: 900px) {
            margin-top: 4%;
            text-align: left;
        }
    }
}

.rightImageDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 4%;

    @media (max-width: 920px) {
        flex-direction: column;
        align-items: center;
    }

}

.benefitImageDiv {
    width: 500px;

    @media (max-width: 1300px) {
        width: 400px;
    }

    @media (max-width: 920px) {
        width: 100%;
    }

    @media (max-width: 500px) {
        width: 110%;
    }
}

.benefitTextDiv {
    text-align: left;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        width: 60%;

        @media (max-width: 1300px) {
            width: 70%;
        }

        @media (max-width: 1100px) {
            width: 80%;
        }

        @media (max-width: 920px) {
            width: 100%;
        }
    }


    @media (max-width: 1000px) {
        width: 80%;
    }

    @media (max-width: 920px) {
        width: 100%;
    }

    h3 {
        width: 100%;
        font-weight: 600;
        margin-bottom: 5%;
        width: 60%;

        @media (max-width: 1300px) {
            width: 70%;
        }

        @media (max-width: 1100px) {
            width: 80%;
        }

        @media (max-width: 920px) {
            width: 100%;
        }
    }
}

.benefitsContentDiv {
    width: 60%;

    @media (max-width: 900px) {
        width: 90%;
    }




}

.benefitsImage {
    width: 70%;

    @media (max-width: 900px) {
        width: 40%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
}

.leftImageDiv {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 4%;

    h3 {
        text-align: right;
    }

    p {
        text-align: right;
    }

    @media (max-width: 920px) {
        flex-direction: column;
        align-items: center;

        p {
            text-align: left;
        }

        h3 {
            text-align: left;
        }
    }
}