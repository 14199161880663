.feedbackContainer {
    background-color: white;
    color: #0E334F;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.contentBox {
    width: 90%;
    text-align: left;

    h3 {
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 2%;

        @media (max-width: 600px) {
            margin-bottom: 4%;
        }
    }
}

.fcFounderBox {
    float: right;

    p {
        font-size: 15px;
        text-align: center;
        color: black;
    }

    @media (max-width: 700px) {
        float: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.fcFounderImage {
    height: 300px;
    padding: 10px;

    @media (max-width: 700px) {
        width: 90%;
        height: auto;
    }
}

.italicText {
    font-style: italic;
}