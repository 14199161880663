.extraDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    //border: 1px solid red;
    padding-top: 3%;
    padding-bottom: 3%;
}

.extraText {
    width: 80%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 3%;

}

.extraButton {
    border-radius: 0;
    color: white;
    background-color: #0E334F;
    border: none;
    padding: 10px 30px 10px 30px;
}
