.serviceCardContainer {
    color: white;
    background-color: #006EE9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 4%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 600px) {
        padding-top: 6%;
        padding-bottom: 6%;
    }

    h2 {
        font-weight: 600;
        margin-bottom: 4%;

        @media (max-width: 1100px) {
            width: 90%;
            text-align: center;
        }

        @media (max-width: 600px) {
            margin-bottom: 6%;
        }
    }
}

.serviceCardHolder {
    display: flex;
    flex-direction: row;
    gap: 70px;
    width: 90%;
    
    @media (max-width: 1100px){
        flex-direction: column;
        gap: 50px;
    }
    
}

.serviceCard {
    background-color: #0E334F;
    flex-basis: 33.3%;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    padding-bottom: 2%;
    padding-top: 2%;

    @media (max-width: 1100px) {
        padding-top: 5%;


    }

    @media (max-width: 1200px) {
        padding: 15px;
    }
    

    h3 {
        font-weight: 600;
        font-size: 26px;
    }

    p {
        width: 75%;
        margin-top: 2%;

        @media (max-width: 1100px) {
            width: 80%;
        }
    }
}

.lolImage {
    margin-left: 13px;
}

.serviceCardImageDiv {

    margin-bottom: 4%;
    
    @media(max-width: 1100px){
        width: 40%;
    }

    @media(max-width: 900px) {
        width: 60%;
    }

    @media(max-width: 600px) {
        width: 100%;
    }
}