.linkSeg {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 600px) {
        padding-top: 4%;
    }
}

.linkSegOne {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 900px) {
        width: 100%;
    }

    h2 {
        margin-top: 2%;
        margin-bottom: 2%;
        color: #0E334F;
        font-weight: 600;

        @media (max-width: 600px) {
            margin-bottom: 4%;
        }

        @media (max-width: 900px) {
            width: 95%;
            text-align: left;
        }
    }

    p {
        width: 60%;
        font-family: 'Poppins', sans-serif;
        color: #0E334F;
        margin-bottom: 30px;
        text-align: center;

        @media (max-width: 900px) {
            width: 95%;
            text-align: left;
        }
    }
}

.linkSegTwo {
    display: flex;
    gap: 35px;
    font-size: 36px;

    div {
        cursor: pointer;
    }
}

.linkSegThree {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
    color: #fff
}

.linkContainer {
    background-color: #006EE9;
    padding: 15px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    cursor: pointer;
    
    p{
        margin-bottom: 0
    }
    
}