.discoveryDiv {
    width: 100%;
    background-image: url('../images/goddardsCaseStudy.jpg');
    background-size: cover;
    background-position-y: 50%;
    background-repeat: no-repeat;
    //background-attachment: fixed;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media(max-width: 700px) {
        width: 100%
    }
}

.mainDiscoveryDiv {
    display: flex;
    justify-content: center;
    color: white;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 700px) {
        //padding-bottom: 6%;
        padding-left: 0;
        padding-right: 0;
    }

    h3 {
        width: 40%;
        margin-top: 10%;
        margin-bottom: 5%;
        font-weight: 700;
        font-size: 32px;

        @media (max-width: 650px) {
            width: 70%;
        }
    }
}

.landingButton {
    background-color: white;
    color: #0E334F;
    border: none;
    border-radius: 0;
    padding: 10px 25px 10px 25px;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5%;
}
