//**Section container
.segThree {
    display: flex;
    flex-direction: row;
    background-color: #006EE9;
    padding-bottom: 10%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        padding-top: 5%;
        padding-bottom: 15%;
    }
}
//Section left/Top
.segThreeLeft {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
    text-align: left;
    padding-bottom: 2%;

    @media (max-width: 900px) {
        width: 95%;
    }


    h2 {
        color: white;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        width: 60%;
        margin-bottom: 4%;

        @media (max-width: 900px) {
            width: 100%;
            margin-bottom: 6%;
        }
    }

    p {
        color: white;
        //font-weight: 200;
        font-family: 'Poppins', sans-serif;
        width: 60%;
        margin-bottom: 4%;

        @media (max-width: 900px) {
            width: 100%;
            margin-bottom: 6%;
        }
    }

    ul {
        width: 60%;
        padding-left: 15px;
        padding-bottom: 2%;
        color: white;

        @media (max-width: 900px) {
            width: 80%;
        }

        li span {
            position: relative;
            left: 20px;
        }
    }

    button {
        border: 2px solid #006EE9;
        color: #006EE9;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        padding: 10px;
        padding-left: 40px;
        padding-right: 40px;
        margin-top: 2%;
        margin-bottom: 2%;
    }
}
//Section right/Bottom
.segThreeRight {
    height: 90%;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
    margin-top: 5%;

    @media (max-width: 900px) {
        justify-content: right;
    }
}
//**Coolest duck image
.drDuck {
    height: 450px;
    width: 350px;
    object-fit: cover;
    z-index: 1;
    position: absolute;

    @media (max-width: 900px) {
        height: 400px;
        width: 300px;
    }

    @media (max-width: 450px) {
        width: 200px;
        height: 300px;
    }
}
// Border behind coolest duck
.magicBorder {
    position: relative;
    border: 5px solid white;
    background-color: transparent;
    z-index: 0;
    height: 450px;
    width: 350px;
    bottom: -45px;
    right: -45px;

    @media (max-width: 900px) {
        height: 400px;
        width: 300px;
        bottom: -25px;
        right: -25px;

        min-width: 300px;
    }

    @media (max-width: 450px) {
        width: 200px;
        height: 300px;
        bottom: -25px;
        right: -25px;

        min-width: 150px;
    }
}