//Section container
.segTwo {
    background-color: #006EE9;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;

    @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 1000px) {
        flex-direction: column;
    }
}

//Image section
.segTwoLeft {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
        width: 100%;
        margin-bottom: 2%;
    }
}
//Image
.daBoyzWorking {
    width: 70%;

    @media (max-width: 1000px) {
        width: 95%;
    }
}
//Text section
.segTwoRight {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
        width: 100%;
        align-items: center;
        padding: 0;
        text-align: left;
    }

    h2 {
        color: white;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 5%;
        width: 80%;

        @media (max-width: 1200px) {
            margin-top: 4%;
            width: 95%;
        }

        @media (max-width: 900px) {
            width: 95%;
        }
    }

    p {
        width: 80%;
        color: white;
        margin-bottom: 4%;
        font-weight: 400;

        @media (max-width: 1200px) {
            width: 95%;
        }

        @media (max-width: 900px) {
            width: 95%;
        }
    }
}